import Role from "@/models/enums/role";

export const auth = {
    namespaced: true,
    state: {
        user_id: null,
        user_name: null,
        account_type: null,
        role: null,
        access_token: null,
        access_token_expired: null,
        access_token_issued: null,
        refresh_token: null,
        refresh_token_expired: null,
        refresh_token_issued: null,
        is_provisional_password: null,
        is_signed_in: false,
        department_id: null,
    },
    mutations: {
        //APIのコールバックでよぶには actions が必要なはずだが
        //普通に呼べている件
        setAccessToken (state, param) {
            state.user_id = param.user_id;
            state.user_name = param.user_name;
            state.account_type = param.account_type;
            state.role = param.role ?? Role.STAFF;
            state.access_token = param.access_token;
            state.access_token_expired = param.access_token_expired;
            state.access_token_issued = param.access_token_issued;
            state.refresh_token = param.refresh_token;
            state.refresh_token_expired = param.refresh_token_expired;
            state.refresh_token_issued = param.refresh_token_issued;
            state.is_provisional_password = param.is_provisional_password;
            state.is_signed_in = true;
            state.department_id = param.department?.department_id;
        },
        //ログアウト時処理
        signOut (state) {
            state.user_id = null;
            state.user_name = null;
            state.account_type = null;
            state.role = null;
            state.access_token = null;
            state.access_token_expired = null;
            state.access_token_issued = null;
            state.refresh_token = null;
            state.refresh_token_expired = null;
            state.refresh_token_issued = null;
            state.is_provisional_password = null;
            state.is_signed_in = false;
        },
    },
    getters: {
        // ユーザ管理権限
        canManageUser: (state) => {
            return (state.role === Role.MANAGER);
        },
        // スプレッドシート再同期
        canResyncSpreadsheet: (state) => {
            return (state.role === Role.MANAGER);
        },
        // 案件情報等編集権限
        canEditProject: (state) => {
            return (state.role === Role.MANAGER) || (state.role === Role.STAFF);
        },
        // マスタ管理編集権限
        canEditMaster: (state) => {
            return (state.role === Role.MANAGER);
        },
        // 共有リスト編集機能「
        canEditSharedList: (state) => {
            return (state.role === Role.MANAGER);
        },
        // 他人の活動を削除可能
        deleteOthersActivity: (state) => {
            return (state.role === Role.MANAGER);
        },
    },
}
