export const dashboard = {
    namespaced: true,
    state: {
        expired_follow_user_id: null,
    },
    mutations: {
        setExpiredFollowUserId(state, value) {
            state.expired_follow_user_id = value;
        },
    },
}
