import Enum from './enum'

/**
 * 権限
 */
class Role extends Enum {
    static MANAGER = 1; //管理者
    static READONLY = 2; //閲覧のみ
    static STAFF = 9; //一般

    static values() {
        return {
            [this.MANAGER]: '管理者',
            [this.READONLY]: '閲覧のみ',
            [this.STAFF]: '一般',
        }
    }
}

export default Role;
