// 日付系
export function dateFormat(format, date_string, when_empty = null) {
    if (!date_string) {
        return when_empty;
    }

    let date = null;
    if (date_string instanceof Date) {
        date = date_string;
    } else {
        date = new Date(date_string);
    }

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let dow = date.getDay();
    let dows = ['日', '月', '火', '水', '木', '金', '土'];

    if (Number.isNaN(year)) {
        return when_empty;
    }

    return format
        .replace('Y', year)
        .replace('y', year.toString().substring(2))
        .replace('m', ('0' + month).slice(-2))
        .replace('n', month)
        .replace('d', ('0' + day).slice(-2))
        .replace('j', day)
        .replace('H', ('0' + hour).slice(-2))
        .replace('i', ('0' + min).slice(-2))
        .replace('s', ('0' + sec).slice(-2))
        .replace('w', dow)
        .replace('Q', dows[dow]);
}

export function dateYnj(date_string, when_empty = null) {
    return dateFormat('Y/n/j', date_string, when_empty)
}

export function dateYm(date_string, when_empty = null) {
    return dateFormat('Y/m', date_string, when_empty)
}

export function dateYmd(date_string, when_empty = null) {
    return dateFormat('Y/m/d', date_string, when_empty)
}

export function dateMd(date_string, when_empty = null) {
    return dateFormat('n月j日', date_string, when_empty)
}

export function dateYmdForInputDate(date_string, when_empty = null) {
    return dateFormat('Y-m-d', date_string, when_empty)
}

export function dateYmdHi(date_string, when_empty = null) {
    return dateFormat('Y/m/d H:i', date_string, when_empty)
}

export function dateNj(date_string, when_empty = null) {
    return dateFormat('n/j', date_string, when_empty)
}

export function today(format = 'Y-m-d') {
    let today = new Date();
    return dateFormat(format, today);
}

export function now(format = 'Y-m-d H:i:s') {
    let now = new Date();
    return dateFormat(format, now);
}

// 〇月〇旬の表示（ex: 1月上旬）
export function dateMonthPeriod(date_string, when_empty = null) {
    if ('1' === dateFormat('j', date_string, when_empty)) {
        return dateFormat('n月', date_string, when_empty) + '上旬';
    } else if ('11'  === dateFormat('j', date_string, when_empty)) {
        return dateFormat('n月', date_string, when_empty) + '中旬';
    } else if ('21'  === dateFormat('j', date_string, when_empty)) {
        return dateFormat('n月', date_string, when_empty) + '下旬';
    } else {
        return '';
    }
}

// 配列の要素をDateオブジェクトに変換し、最新のdateを取得する
export function latestDate(dates) {
    if (!dates.length) {
        return null;
    }

    // 最初の要素を最新のdateとする
    let latest_date = new Date(dates[0]);

    for (let i = 1; i < dates.length; i++) {
        const current_date = new Date(dates[i]);

        if (current_date > latest_date) {
            latest_date = current_date;
        }
    }

    return latest_date;
}