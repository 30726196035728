export const projectList = {
    namespaced: true,
    state: {
        is_list: true,
        is_list_mode: true,
        condition: null,
        search_list: null,
    },
    mutations: {
        setIsList(state, value) {
            state.is_list = value;
        },
        setIsListMode(state, value) {
            state.is_list_mode = value;
        },
        setCondition(state, value) {
            state.condition = value;
        },
        setSearchList(state, value) {
            state.search_list = value;
        },
    },
    actions: {
        setCondition({ commit }, value) {
            return commit('setCondition', value);
        }
    },
    getters: {
        condition: state => state.condition
    },
}
