import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import { condition } from './condition.js';
import { auth } from './auth.js';
import { projectList } from './project-list.js';
import { dashboard } from './dashboard.js';

const store = createStore({
    modules: {
        auth,
        condition,
        projectList,
        dashboard,
    },
    plugins: [
        createPersistedState({
            key: 'pim',
            paths: ['auth', 'dashboard'],
        })
    ]
})

export default store
