<template>
    <header class="page-title">
        <h1><i class="bi bi-key"></i> ログイン</h1>
    </header>

    <section class="section">

        <h2 class="section-title">Googleアカウントでログイン</h2>

        <a :href="google_auth_url" class="btn btn-primary btn-lg"><i class="bi bi-google"></i> ログイン</a>

        <div class="mt-3">@3a-c.com の Google アカウントでのみログインできます。<br>
        他の Google アカウントでログインしている場合、エラーとなりますので、 <a href="https://myaccount.google.com/?hl=ja" target="_blank">Google アカウント</a> からログアウトしてください。</div>

    </section>

    <section class="section" v-if="is_local">

        <h2 class="section-title">開発用ログイン</h2>

        <button class="btn btn-primary btn-lg" @click="signinTest"><i class="bi bi-google"></i> ログイン</button>

    </section>

</template>

<script>
import axios from 'axios';

export default {
    components: {},
    name: 'Signin',
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
    ],
    data() {
        return {
            login_key: null,
            password: null,
            google_auth_url: process.env.VUE_APP_API_URI + '/redirect/google-auth',
            is_local: (process.env.VUE_APP_ENV === 'local'),
        }
    },
    mounted() {
        let code = this.$route.query.code;
        if (code) {
            this.signinGoogle(code);
        }
    },
    methods: {
        signinTest() {
            this.startScreenLoading();
            axios.post(process.env.VUE_APP_API_URI + '/signin')
            .then((response) => {
                this.$store.commit('auth/setAccessToken', response.data);
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                //※ここは $http 使っていないので、自力でメッセージ表示が必要
                if (error.response && error.response.status === 422) {
                    this.showErrorMessage('ログインに失敗しました');

                } else if (error.response && error.response.data && error.response.data.message) {
                    this.showErrorMessage(error.response.data.message);
                    console.log(error.response.data)

                } else {
                    this.showErrorMessage('エラーが発生しました');
                    console.log(error);
                }
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        signinGoogle(code) {
            this.startScreenLoading();
            axios.post(process.env.VUE_APP_API_URI + '/signin/google', {
                code,
            })
            .then((response) => {
                this.$store.commit('auth/setAccessToken', response.data);
                //リダイレクト指定があれば
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                //※ここは $http 使っていないので、自力でメッセージ表示が必要
                if (error.response && error.response.status === 422) {
                    this.showErrorMessage('Googleログインに失敗しました');

                } else if (error.response && error.response.data && error.response.data.message) {
                    this.showErrorMessage(error.response.data.message);
                    console.log(error.response.data)

                } else {
                    this.showErrorMessage('エラーが発生しました');
                    console.log(error);
                }
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
